import React, { Component } from "react";
import AuthContext from "../../auth/context/AuthContext";
import { redirect } from "react-router-dom";
import Categories from "../../components/education/Categories";
import { Button } from "../../components/global/Button";
import { movieApi } from '../../api/MovieApi'
import { handleLogError } from '../../utils/Logger'


var category = {
  name: "root",
  id: 0,
  parentCategory: 0,

}

export class CategoryViewPage extends Component {
  static contextType = AuthContext;

  state ={
    categories:[],
  }
  constructor(props) {
    super(props);

  }
  componentDidMount(){
    const Auth = this.context
    this.getCategories(Auth)
    console.log(this.context)


  }
  getCategories(Auth) {
    //console.log(Auth)
    // console.log(Auth.userIsAuthenticated)
    if (Auth.userIsAuthenticated) {
      const user = Auth.getUser()
      movieApi.getCategories(user)
        .then(response => {
          const categories = response.data
         // console.log(response.data)
          this.setState({
            Categorys: categories
          })
        })
        .catch(error => {
          handleLogError(error)
          if (error.response && error.response.data) {
            const errorData = error.response.data
            // console.log(error.response.data)
            let errorMessage = 'Invalid fields'
            if (errorData.status === 409) {
              errorMessage = errorData.message
            } else if (errorData.status === 400) {
              errorMessage = errorData.errors[0].defaultMessage
            }
            this.setState({
              isError: true,
              errorMessage
            })
          }
        })
    }
  }
  render() {
    const { Categorys } = this.state;

    return (
      <div>
        <a href="/category/edit">
          <Button className="border-2 border-indigo-800 border-solid cursor-pointer font-semibold font-worksans h-[46px] min-w-[150px] py-[13px] rounded-[20px] text-base text-center text-indigo-800 uppercase">
            Add A Category
          </Button>
        </a>
        <Categories
          categories={Categorys}
        ></Categories>
      </div>
    );
  }
}
