import React, { Component, useContext, useMemo } from 'react'
import AuthContext from './AuthContext'
import { redirect } from 'react-router-dom'
import UserContext from './roles/UserContext'
import AdminContext from './roles/AdminContext'
import { handleLogError } from '../../utils/Logger'
import { getRoles } from '@testing-library/react'

const SecurityContext = React.createContext();
var Enumerator = {user:"USER", admin:"ADMIN"}

class SecurityProvider extends Component {
  static contextType = AuthContext;

  state = {
    role: null
  }

  componentDidMount() {
    //console.log("Security Context")
    this.getAccess()
  }

  getAccess = () =>{
    const Auth = this.context
    this.role = Auth.getRoles()
    if(this.role !== null || undefined)  {
      this.role = this.role.split(',')[0]
    }
    else
      this.role = null
    return this.role
  }
  
  render() {
    const {children} = this.props;
    const {getAccess} = this
    return (
        <SecurityContext.Provider value={{ getAccess }}>
          {children}
        </SecurityContext.Provider>
    )
  }
}
export default SecurityContext

export function useSecurity() {
  return useContext(SecurityContext)
}

// const AccessLevel = () =>{
//   try {
//     var getAccess = useMemo(() => {
//       return () => {
//           switch (this.role) {
//               case Enumerator.user:
//                   return (
//                       <UserContext/>
//                   );
//               case Enumerator.admin:
//                   return (
//                       <AdminContext/>
//                   );
//               default:
//                 return(
//                   redirect('/login')
//                 );
//           }
//       }
//   }, [this.role])
//   return getAccess
// } catch (error) {
// }
// }
export { SecurityProvider }