import React from "react";
import Week from "./Week";
import List from "../../global/List";

const Weeks = (props) => {
  return (
    <>
      <List
        className="bg-white-A700 flex flex-col items-start rounded-[20px] w-auto"
        orientation="vertical"
      >
        {props?.weeks.forEach((week) => {
          <Week
            number={week.number}
            isAvailable={week.isAvailable}
            item={week.item}
          ></Week>;
        })}
      </List>
    </>
  );
};
Weeks.defaultProps = {
  selectedWeekId: 0,
  weeks: [],
};
export default Weeks;
