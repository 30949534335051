import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Menu } from 'semantic-ui-react'
import { useAuth } from '../auth/context/AuthContext'
import { useSecurity } from '../auth/context/SecurityContext'

function Navbar() {
  const { getUser, userIsAuthenticated, userLogout } = useAuth()
  const { getAccess } = useSecurity()
  const logout = () => {
    userLogout()
  }

  const enterMenuStyle = () => {
    return userIsAuthenticated() ? { "display": "none" } : { "display": "block" }
  }

  const logoutMenuStyle = () => {
    return userIsAuthenticated() ? { "display": "block" } : { "display": "none" }
  }

  const adminPageStyle = () => {
    const user = getUser()
    //console.log(getUser())
    const role = getAccess()
    //console.log(getAccess())
    return user && role === 'ADMIN' ? { "display": "block" } : { "display": "none" }
  }

  const userPageStyle = () => {
    const user = getUser()
    const role = getAccess()
    return user && role === 'USER' ? { "display": "block" } : { "display": "none" }
  }
  
  const getUserName = () => {
    const user = getUser()
    return user ? user.data.name : ''
  }

  return (
    <Menu inverted color='teal' stackable size='massive' style={{borderRadius: 0}}>
      <Container>
        <Menu.Item header>My Wellness Edge</Menu.Item>
        <Menu.Item as={Link} exact='true' to="/home">Home</Menu.Item>
        <Menu.Item as={Link} to="/adminpage" style={userPageStyle()}>AdminPage</Menu.Item>
        <Menu.Item as={Link} to="/category" style={adminPageStyle()}>Categories</Menu.Item>
        <Menu.Item as={Link} to="/askmeanything" style={adminPageStyle()}>AskMeAnything</Menu.Item>
        <Menu.Item as={Link} to="/contentpost" style={adminPageStyle()}>Content Posts</Menu.Item>
        <Menu.Item as={Link} to="/home" style={userPageStyle()}>Home</Menu.Item>
        <Menu.Menu position='right'>
          <Menu.Item as={Link} to="/login" style={enterMenuStyle()}>Login</Menu.Item>
          <Menu.Item as={Link} to="/signup" style={enterMenuStyle()}>Sign Up</Menu.Item>
          <Menu.Item header style={logoutMenuStyle()}>{`Hi ${getUserName()}`}</Menu.Item>
          <Menu.Item as={Link} to="/" style={logoutMenuStyle()} onClick={logout}>Logout</Menu.Item>
        </Menu.Menu>
      </Container>
    </Menu>
  )
}

export default Navbar
