import React from 'react'
import { AuthProvider } from './auth/context/AuthContext'
import {SecurityProvider} from './auth/context/SecurityContext'
import PrivateRoute from './auth/context/PrivateRoute'
import Home from './pages/user/Home'
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import Login from './auth/Login'
import Signup from './auth/Signup'
import OAuth2Redirect from './auth/OAuth2Redirect'

import { ContentPostViewPage } from './pages/admin/ContentPostViewPage'
import { Module } from './pages/admin/Module'
import { CategoryViewPage } from './pages/admin/CategoryViewPage'
import { CategoryEdit } from './pages/admin/CategoryEditPage'
import { ContentPostEdit } from './pages/admin/ContentPostEditPage'
import { ChatPage } from './pages/admin/ChatPage'



import Layout from './layout/Layout'
import Navbar from './layout/Navbar'

function App() {
  return (
    // console.log("App"),
    <AuthProvider>
      <SecurityProvider>
      <BrowserRouter>
        <Navbar></Navbar>
          <Routes>
            {/* {console.log("inputting auth routes")} */}
            <Route path='/' element={<Login />} />
            <Route path='/home' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/oauth2/redirect' element={<OAuth2Redirect />} />
            {/* Admin Pages */}
            <Route path="/category" element={<PrivateRoute><CategoryViewPage /></PrivateRoute>}/>
            <Route path="/category/edit" element={<PrivateRoute><CategoryEdit /></PrivateRoute>}/>
            <Route path="/contentpost/edit" element={<PrivateRoute><ContentPostEdit /></PrivateRoute>}/>
            <Route path="/module" element={<PrivateRoute><Module /></PrivateRoute>}/>
            <Route path="/contentpost" element={<PrivateRoute><ContentPostViewPage /></PrivateRoute>}/>
            <Route path="/askmeanything" element={<PrivateRoute><ChatPage /></PrivateRoute>}/>

            <Route path="*" element={<Navigate to="/" />}/>
          </Routes>
          {/* <Layout/> */}
          {/* <SecurityProvider/> */}
      </BrowserRouter>
      </SecurityProvider>
    </AuthProvider>



  )
}

export default App
