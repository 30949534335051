import React, { Component } from "react";
import { config } from "../../utils/Constants";
import AuthContext from "../../auth/context/AuthContext";
import { Input } from "../../components/global/Input";
import { Button } from "../../components/global/Button";

export class ContentPostEdit extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      Categorys: [],
      modalTitle: "",
      CategoryName: "",
      CategoryId: 0,

      CategoryIdFilter: "",
      CategoryNameFilter: "",
      CategorysWithoutFilter: [],
    };
  }

  refreshList() {
    fetch(config.API_BASE_URL + "Category", { mode: "no-cors" })
      .then((response) => response.json())
      .then((data) => {
        this.setState({ Categorys: data, CategorysWithoutFilter: data });
      });
  }

  componentDidMount() {
    const Auth = this.context;
    const user = Auth.getUser();
    const isAdmin = user.data.rol[0] === "ADMIN";
    this.setState({ isAdmin });

    this.refreshList();
  }

  changeCategoryName = (e) => {
    this.setState({ CategoryName: e.target.value });
  };

  createClick() {
    fetch(config.API_BASE_URL + "Category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CategoryName: this.state.CategoryName,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          alert(result);
          this.refreshList();
        },
        (error) => {
          alert("Failed");
        }
      );
  }

  updateClick() {
    fetch(config.API_BASE_URL + "Category", {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CategoryId: this.state.CategoryId,
        CategoryName: this.state.CategoryName,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          alert(result);
          this.refreshList();
        },
        (error) => {
          alert("Failed");
        }
      );
  }

  deleteClick(id) {
    if (window.confirm("Are you sure?")) {
      fetch(config.API_BASE_URL + "Category/" + id, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result) => {
            alert(result);
           // this.refreshList();
          },
          (error) => {
            alert("Failed");
          }
        );
    }
  }

  render() {
    const { Categorys, modalTitle, CategoryId, CategoryName } = this.state;

    return (
      <div class="flex justify-center items-center">
        <div class="w-full max-w-5xl">
          <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div class="mb-4">
              <Input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-first-name"
                type="text"
                placeholder="Sleep is good for you"
                label="Title"
              />
            </div>
            
            <div class="mb-4">
              <Input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-zip"
                type="text"
                placeholder="lorem ipsum..."
                label="Post"
              />
            </div>
            <div class="mb-4">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                Resources
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                >
                  <option>Resource 1</option>
                  <option>Resource 2</option>
                  <option>Resource 3</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex justify-center items-center">
              <Button className="border-2 border-indigo-800 border-solid cursor-pointer font-semibold font-worksans h-[46px] min-w-[150px] py-[13px] rounded-[20px] text-base text-center text-indigo-800 uppercase">
                Add
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
