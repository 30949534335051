import React, { Component } from "react";
import AuthContext from "../../auth/context/AuthContext";
import { redirect } from "react-router-dom";
import Categories from "../../components/education/Categories";
import { Button } from "../../components/global/Button";
import { Text } from "../../components/global/Text";
export class ContentPostViewPage extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
        ContentPost: [],
    };
  }

  render() {
    const { ContentPost } = this.state;

    return (
      <div>
        <a href="/contentpost/edit">
          <Button className="border-2 border-indigo-800 border-solid cursor-pointer font-semibold font-worksans h-[46px] min-w-[150px] py-[13px] rounded-[20px] text-base text-center text-indigo-800 uppercase">
            Add A Content Post
          </Button>
        </a>
        <Text>
          Here is the author of the post 
        </Text>
        <Text>
          Here is the content of the post 
        </Text>
        <Text>
          Here is the resources of the post 
        </Text>
      </div>
    );
  }
}
