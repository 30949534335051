import React from "react";

import List from "../../global/List";
import { Text } from "../../global/Text";

import Category from "./Category";
const Categories = (props) => {
  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col gap-5 h-[814px] md:h-auto items-start justify-start w-[500px] sm:w-full">
          <List
            className="flex flex-col gap-5 items-center w-full"
            orientation="vertical"
          >
            {props.categories.map((cat, i) => {
              return (
                <div>
                  <Category id={cat.id} title={cat.name} />{/*weeks={cat.weeks} */}
                </div>
              );
            })}
          </List>
        </div>
      </div>
    </>
  );
};
Categories.defaultProps = {
  categories: [],
};
export default Categories;
