import React from "react";

import { Text } from "../../global/Text";
import { Img } from "../../global/Img";


const Week = (props) => {
  return (
    <>
      <div className="border-b-2 border-green-800 border-solid flex flex-row gap-4 h-[60px] md:h-auto items-center justify-center my-0 sm:px-5 px-[30px] w-auto">
        <Text
          className="capitalize text-[22px] text-center text-green-800 sm:text-lg md:text-xl w-auto"
          size="txtWorkSansRomanSemiBold22Green800"
        >
          Week {props?.number}
        </Text>
        <div className="bg-green-800 flex flex-col items-center justify-start px-2.5 py-[5px] rounded-[20px] w-auto">
          if(isAvailable)
          {
            <Img
              className="h-8 w-8"
              src="images/img_unlock_white.svg"
              alt="unlock"
            />
          }
          else
          {
            <Img
              className="h-8 w-8"
              src="images/img_lock_white.svg"
              alt="lock"
            />
          }
        </div>
      </div>
    </>
  );
};
Week.defaultProps = {
  number: 0,
  isAvailable: false,
};
export default Week;
