const prod = {
  url: {
    API_BASE_URL:"https://mywellnessapi.oldroadsoftware.com",

    // API_BASE_URL: 'https://mybeapp.herokuapp.com',
    // OAUTH2_REDIRECT_URI: 'https://myfeapp.herokuapp.com/oauth2/redirect'
  }
}

const dev = {
  url: {
    //for local testing
    API_BASE_URL:"https://mywellnessapi.oldroadsoftware.com",
    // API_BASE_URL:"https://localhost:7215",
    PHOTO_URL:"http://localhost:5001/Photos/",
    OAUTH2_REDIRECT_URI: 'http://localhost:3000/oauth2/redirect'
  }
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod