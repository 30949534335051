import React from "react";

const sizeClasses = {
  txtWorkSansRomanRegular12Indigo800: "font-normal font-worksans",
  txtSpaceMonoBold22: "font-bold font-spacemono",
  txtSpaceMonoBold67: "font-bold font-spacemono",
  txtWorkSansRomanSemiBold51: "font-semibold font-worksans",
  txtSpaceMonoRegular12: "font-normal font-spacemono",
  txtSpaceMonoRegular14: "font-normal font-spacemono",
  txtWorkSansRomanRegular16: "font-normal font-worksans",
  txtWorkSansRomanSemiBold22Indigo800: "font-semibold font-worksans",
  txtMontserratRegular16: "font-montserrat font-normal",
  txtWorkSansRomanSemiBold28: "font-semibold font-worksans",
  txtWorkSansRomanSemiBold51Indigo800: "font-semibold font-worksans",
  txtWorkSansRomanSemiBold22Green800: "font-semibold font-worksans",
  txtSpaceMonoBold38: "font-bold font-spacemono",
  txtSpaceMonoRegular16: "font-normal font-spacemono",
  txtWorkSansRomanRegular12: "font-normal font-worksans",
  txtSpaceMonoBold51: "font-bold font-spacemono",
  txtMontserratMedium16: "font-medium font-montserrat",
  txtWorkSansRomanBold24: "font-bold font-worksans",
  txtWorkSansRomanSemiBold22Gray600: "font-semibold font-worksans",
  txtWorkSansRomanSemiBold67: "font-semibold font-worksans",
  txtSpaceMonoBold22Indigo800: "font-bold font-spacemono",
  txtWorkSansRomanSemiBold22: "font-semibold font-worksans",
  txtWorkSansRomanSemiBold38: "font-semibold font-worksans",
  txtWorkSansRomanSemiBold16: "font-semibold font-worksans",
  txtWorkSansRomanSemiBold38Indigo800: "font-semibold font-worksans",
  txtSpaceMonoBold24: "font-bold font-spacemono",
  txtWorkSansRomanRegular22: "font-normal font-worksans",
  txtWorkSansRomanRegular16Indigo800: "font-normal font-worksans",
  txtSpaceMonoBold28: "font-bold font-spacemono",
  txtSpaceMonoRegular16Indigo800: "font-normal font-spacemono",
};

const Text = ({ children, className = "", size, as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };
