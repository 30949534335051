import React from "react";

import { Button } from '../../global/Button';
import {  Img  } from '../../global/Img';
import {  List } from '../../global/List';
import { Text } from '../../global/Text';

import Weeks from "../Weeks";
const Category = (props) => {
  console.log(props.title)
  return (
    <>
      <div className="bg-indigo-800 flex flex-col gap-2.5 items-center justify-start pr-2.5 py-3 rounded-[20px] w-full">
        <div className="flex flex-col h-[60px] md:h-auto items-center justify-start px-5 w-auto sm:w-full">
          <div className="flex sm:flex-col flex-row gap-5 items-center justify-start w-[430px] sm:w-full">
            <Text
              className="bg-red-100_93 justify-center px-[11px] py-0.5 rounded-[15px] text-base text-center text-indigo-800 w-8"
              size="txtSpaceMonoRegular16Indigo800"
            >
              {props?.weeksCompleted}
            </Text>
            <div className="flex flex-row gap-5 items-center justify-between w-full">
              <div className="flex flex-col items-end justify-start w-8">
                <Img className="h-8 w-8" src={props?.image} alt="clock" />
              </div>
              <div className="flex flex-col items-start justify-center w-auto">
                <Text
                  className="capitalize text-[22px] sm:text-lg text-white-A700 md:text-xl w-auto"
                  size="txtWorkSansRomanSemiBold22"
                >
                  {props?.title}
                </Text>
              </div>
              <div className="flex flex-col items-start justify-center w-auto">
                <Text
                  className="capitalize text-[22px] sm:text-lg text-right text-white-A700 md:text-xl w-auto"
                  size="txtWorkSansRomanSemiBold22"
                >
                  {props?.percentageDone}%
                </Text>
              </div>
            </div>
          </div>
        </div>
        {/* if(isSelected){<Weeks weeks={props?.weeks}></Weeks>} */}
      </div>
    </>
  );
};
Category.defaultProps = {
  isSelected: false,
  id: 0,
  title: "",
  weeks: [],
  weeksCompleted: 0,
  image: "",
  percentageDone: 0,
};
export default Category;
