import React, { Component } from "react";
import AuthContext from "../../auth/context/AuthContext";
import { redirect } from "react-router-dom";
import Categories from "../../components/education/Categories";
import { Button } from "../../components/global/Button";
import { Text } from "../../components/global/Text";
import { Input } from "../../components/global/Input";
export class ChatPage extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      Messages: [],
    };
  }

  render() {
    const { Messages } = this.state;

    return (
      <div class="flex justify-center items-center">
        <div class="w-full max-w-6xl">
          <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div class="mb-4">
              {" "}
              <div class="flex justify-center items-center">
                <Text> Ask Me Anything</Text>
              </div>
            </div>
            <div class="mb-4">
              <label
                class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                for="grid-state"
              >
                Topic
              </label>
              <div class="relative">
                <select
                  class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="grid-state"
                >
                  <option>Sleep</option>
                  <option>Nutrition</option>
                </select>
                <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg
                    class="fill-current h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <Input
                class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-zip"
                type="text"
                placeholder="Ask me Anything"
                label="Message"
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}
